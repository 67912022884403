<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      no-filters
      :filter-names="['search']"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="$admin.can('oem-customers.edit')"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('oem-customers.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters> </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-business
            v-if="$admin.can('oem-b2b.create')"
            :filter-options="options"
          />
          <edit-business
            v-if="$admin.can('oem-b2b.edit') && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :oemb2b="selectedRow"
          />
          <confirmation-modal
            v-if="$admin.can('oem-b2b.delete')"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateBusiness: () => import("./dialogs/CreateBusiness.vue"),
    EditBusiness: () => import("./dialogs/EditBusiness.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},

      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },

        {
          text: this.$t("business_name"),
          align: "left",
          sortable: false,
          value: "business_name",
          // width: "15%",
        },
        {
          text: this.$t("business_email"),
          align: "left",
          sortable: false,
          value: "business_email",
          // width: "15%",
        },
        {
          text: this.$t("business_phone"),
          align: "left",
          sortable: false,
          value: "business_phone",
          // width: "10%",
        },
        {
          text: this.$t("weight_capacity"),
          align: "left",
          sortable: false,
          value: "avg_vehicle_weight",
          // width: "10%",
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "status",
          // width: "10%",
        },
        ...(this.$admin.hasAccessTo("oem-b2b.delete") ||
          this.$admin.hasAccessTo("oem-b2b.edit")
          ? [
            {
              text: this.$t("actions"),
              align: "left",
              sortable: false,
              value: "actions",
            },
          ]
          : []),
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "oemBusiness/getIsLoading",
      meta: "oemBusiness/getOEMB2bMeta",
      list: "oemBusiness/getOEMB2BList",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const params = {
      name: "oem_business",
      filters: {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        search: this.options.search || undefined,
      },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("oemBusiness/list", this.options).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("oemBusiness/destroy", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        this.toggleDeleteModal("close");
        this.fetchData()
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>